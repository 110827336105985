import React from 'react';
import Layout from '../components/Layout';
import LoadingPage from '../components/LoadingPage';

const BeInTouchPage = () => (
  <Layout logoHeader={true}>
    <LoadingPage text="Our Team Will Be In Touch" />
  </Layout>
);

export default BeInTouchPage;
